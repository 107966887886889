.hoox-hero {
    background-color: #FAFAFA;
}

.hoox-hero__content-wrapper {
    display: flex;
    flex-direction: column-reverse;
}

.hoox-hero__image-mob {
    display: none;
}

.hoox-hero__reviews-icon span {
    height: 15px;
    width: 15px;
}

.hoox-hero-lp-3 .hoox-hero__reviews-icon span {
    width: 16px;
}

.hoox-hero__content { }

.hoox-hero__content-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.hoox-hero__reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.hoox-hero__reviews-icon { }

.hoox-hero__reviews-text {
    color: var(--hoox-color-slate-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    text-decoration: underline;
    text-transform: capitalize;
}

.hoox-hero__title {
    margin: 8px 0;
}

.hoox-hero-lp-3 .hoox-hero__title {
    margin-top: 0;
}

.hoox-hero__description {
    color: var(--hoox-color-slate-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 12px;
}

.hoox-hero__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    order: 1;
}

.hoox-hero__item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.hoox-hero__item img {
    width: 18px;
    height: 18px;
}

.hoox-hero__item p {
    color: var(--hoox-color-slate-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-hero__btn { }

.hoox-hero__info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 8px 0 24px;

    color: var(--hoox-color-slate-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.hoox-hero__media-wrapper {
    position: relative;
}

.hoox-hero__badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 71px;
    height: 85px;
}

@media (min-width: 768px) {
    .hoox-hero__badge {
        top: 32px;
        left: 32px;
        width: 97px;
        height: 116px;
    }
    .hoox-hero-lp-3 .hoox-hero__badge {
        width: 117px;
        height: 140px;
    }
}

@media (min-width: 1023px) {
    .hoox-hero {
        min-height: 590px;
        display: grid;
        place-items: center;
    }

    .hoox-hero__content-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }

    .hoox-hero__content {
        max-width: 560px;
    }

    .hoox-hero__content-inner {
        display: flex;
        flex-direction: column;
        margin-bottom: unset;
    }

    .hoox-hero__reviews {
        justify-content: flex-start;
        margin-top: 10px;
    }


    .hoox-hero__reviews-text {
        color: var(--hoox-color-slate-secondary);
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-decoration: underline;
        text-transform: capitalize;
    }

    .hoox-hero__title {
        text-align: left;
    }

    .hoox-hero__description {
        font-size: 18px;
        margin-bottom: 16px;
        text-align: left;
    }

    .hoox-hero__items {
        align-items: flex-start;
        order: unset;
        margin-bottom: 24px;
    }

    .hoox-hero__item {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .hoox-hero__item p {
        color: var(--hoox-color-slate-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
    }

    .hoox-hero__btn { }

    .hoox-hero__info-wrapper {
        max-width: 320px;
    }

    .hoox-hero__media {
        max-width: 600px;
        max-height: 530px;
    }
}

/*Tablet Portrait Only*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    .hoox-hero__content-wrapper {
        flex-direction: column-reverse;
    }

    .hoox-hero__content-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .hoox-btn-wrapper {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }

    .hoox-hero__description {
        text-align: center;
    }

}

@media (max-width: 768px) {
   
    .hoox-hero-lp-3 .hoox-hero__image-mob {
        display: block;
    }
    .hoox-hero__image-mob-wrapper {
        position: relative;
        height: 288px;
        margin: 24px 0;
    }
    .hoox-hero-lp-3 .hoox-hero__image-mob-wrapper {
        width: 430px;
        align-self: center;
    }
    .hoox-hero-lp-3 .hoox-hero__badge {
        left: 34px;
    }
   
}

@media (max-width: 767px) {
    .hoox-hero-lp-3 .hoox-hero__media-wrapper {
        display: none;
    }
    .hoox-hero-lp-3 .hoox-hero__media {
        display: none;
    }
}

.hoox-hero-lp-3 {
    padding-top: 24px;
}

.hoox-hero-lp-3 .hoox-hero__content-inner {
    align-items: flex-start;
}


.hoox-hero-lp-3 .hoox-hero__item {
    gap: 5px;
}

.hoox-hero-lp-3 .hoox-hero__items {
    align-items: flex-start;
}

@media (min-width: 768px) {
     .hoox-hero__image-mob {
        display: none;
    }

    .hoox-hero-lp-3 .hoox-hero__image {
        max-width: 560px;
        max-height: 496px;
    }

    .hoox-hero-lp-3 .hoox-hero__content {
        min-width: 560px;
    }
    .hoox-hero__image-mob-wrapper {
        display: none;
    }
}



.hoox-hero-lp-2 {
    padding-top: 24px;
}

.hoox-hero-lp-2 .hoox-hero__image-mob-wrapper {
    display: none;
}

.hoox-hero-lp-2 .hoox-hero__media {
    height: 290px;
    margin-bottom: 24px;
}

.hoox-hero-lp-2 .hoox-hero__reviews {
    justify-content: flex-start;
    margin-bottom: -3px;
}

.hoox-hero-lp-2 .hoox-hero__description, .hoox-hero-lp-2 .hoox-hero__title {
    text-align: left;
    max-width: 309px;
}

.hoox-hero-lp-2 .hoox-hero__title {
    margin-bottom: 15px;
    letter-spacing: 0;
    line-height: 33px;
}

.hoox-hero-lp-2 .hoox-hero__description {
    margin-bottom: 19px;
}

.hoox-hero-lp-2 .hoox-hero__items {
    align-items: flex-start;
    margin-top: 22px;
    gap: 9px;
}

.hoox-hero-lp-2 .hoox-hero__item {
    gap: 4px;
}

@media (min-width: 1024px) {
    .hoox-hero-lp-2 .hoox-wrapper, .hoox-hero-lp-3 .hoox-wrapper {
        max-width: unset;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .hoox-hero-lp-2, .hoox-hero-lp-3 {
        padding: 0;
        min-height: 560px;
    }

    .hoox-hero-lp-2 .hoox-hero__content, .hoox-hero-lp-3 .hoox-hero__content {
        max-width: unset;
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .hoox-hero-lp-2 .hoox-hero__content-inner, .hoox-hero-lp-3 .hoox-hero__content-inner {
        width: 530px;
        margin-right: 70px;
    }

    .hoox-hero__content-wrapper {
        height: 100%;
        gap: 0;
    }

    .hoox-hero-lp-2 .hoox-hero__description, .hoox-hero-lp-2 .hoox-hero__title, .hoox-hero-lp-3 .hoox-hero__description, .hoox-hero-lp-3 .hoox-hero__title {
        max-width: 450px;
    }

    .hoox-hero-lp-2 .hoox-hero__media-wrapper, .hoox-hero-lp-3 .hoox-hero__media-wrapper {
        flex: 1;
        height: 100%;
    }

    .hoox-hero-lp-2 .hoox-hero__media, .hoox-hero-lp-3 .hoox-hero__media {
        min-height: 100%;
        min-width: 100%;
    }

    .hoox-hero-lp-2 .hoox-hero__reviews, .hoox-hero-lp-3 .hoox-hero__reviews {
        margin-top: 0;
    }

    .hoox-hero-lp-2 .hoox-hero__items, .hoox-hero-lp-3 .hoox-hero__items {
        margin-top: 0;
        gap: 5px;
        margin-bottom: 29px;
    }

    .hoox-hero-lp-2 .hoox-btn--fixed-width, .hoox-hero-lp-3 .hoox-btn--fixed-width {
        min-width: 244px;
    }

    .hoox-hero-lp-2 .hoox-hero__description, .hoox-hero-lp-3 .hoox-hero__description {
        margin-bottom: 13px;
    }

    .hoox-hero-lp-2 .hoox-hero__title, .hoox-hero-lp-3 .hoox-hero__title {
        margin-bottom: 14px;
        line-height: 55px;
        letter-spacing: -1px;
    }
    .hoox-hero-lp-3 .hoox-hero__title {
        margin-top: 0;
    }

}

@media (max-width: 768px) {
    .hoox-hero-lp-2 .hoox-hero__media .hoox-image {
        object-position: -0.8cm;
    }
    .hoox-hero-lp-3 .hoox-hero__description {
        text-align: left;
        font-size: 16px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    .hoox-hero__content-wrapper {
        gap: 0;
    }

    .hoox-hero-lp-2 .hoox-hero__title {
        line-height: 45px;
        text-align: center;
    }
}
